import {
  SurveyResponseModel,
  SurveysCreateModel,
  SurveysModels,
  SurveysUpdateModel,
} from '../models';

export namespace SurveysAction {
  export class GetAll {
    static readonly type = '[Surveys] Get all surveys';
    constructor(public queries: SurveysModels.Queries) {}
  }

  export class GetByID {
    static readonly type = '[Surveys] Get surveys by ID';
    constructor(
      public queries: SurveysModels.Queries | null,
      public id: number | string
    ) {}
  }

  export class Create {
    static readonly type = '[Surveys] Create surveys';
    constructor(public body: SurveysCreateModel.Request) {}
  }

  export class Update {
    static readonly type = '[Surveys] Update surveys';
    constructor(
      public body: SurveysUpdateModel.Request,
      public surveysID: number | string
    ) {}
  }

  export class ValidationCaptcha {
    static readonly type = '[Surveys] validation recaptcha';
    constructor(public body: SurveyResponseModel.Request, public token:string) {}
  }

  export class Response {
    static readonly type = '[Surveys] survey response';
    constructor(public body: SurveyResponseModel.Request) {}
  }

  export class ResponseSuccess {
    static readonly type = '[Surveys] survey response success';
  }

  export class Delete {
    static readonly type = '[Surveys] Delete surveys';
    constructor(public surveysID: number | string) {}
  }

  export class Failure {
    static readonly type = '[Surveys] Load Error';
    constructor(public error: unknown) {}
  }
}
