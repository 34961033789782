import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SurveysModels } from '../models';
import { SurveysService } from '../services/surveys.service';
import { TableData } from '@carwash-project/modules/ui';
import { SurveysAction } from './surveys.actions';
import { catchError, mergeMap, tap, throwError } from 'rxjs';
import { Navigate } from '@ngxs/router-plugin';

export interface SurveysStateModel {
  surveys: SurveysModels.List | null;
  survey: SurveysModels.Surveys | null;
  filters: SurveysModels.Queries;
  loading: boolean;
  loadingResponse: boolean;
  error: unknown;
}

const initialState: SurveysStateModel = {
  surveys: null,
  survey: null,
  filters: {
    populate: 'all',
    allSurveysForUser: true,
  },
  loadingResponse: false,
  loading: false,
  error: null,
};

@State<SurveysStateModel>({
  name: 'surveys',
  defaults: initialState,
})
@Injectable()
export class SurveysState {
  private readonly surveysService = inject(SurveysService);

  @Selector()
  public static surveys(state: SurveysStateModel) {
    return state.surveys;
  }

  @Selector()
  public static survey(state: SurveysStateModel) {
    return state.survey;
  }

  @Selector()
  public static surveysTable(state: SurveysStateModel) {
    return new TableData(
      state.surveys?.results ?? [],
      state.surveys?.totalCount
    );
  }

  @Selector()
  public static loading(state: SurveysStateModel) {
    return state.loading;
  }

  @Selector()
  public static loadingResponse(state: SurveysStateModel) {
    return state.loadingResponse;
  }

  /** Action Handle Error */
  @Action(SurveysAction.Failure)
  public handleError(
    ctx: StateContext<SurveysStateModel>,
    { error }: SurveysAction.Failure
  ) {
    ctx.patchState({
      error,
      loading: false,
    });
    return throwError(() => error);
  }

  @Action(SurveysAction.ResponseSuccess)
  public responseSuccess(ctx: StateContext<SurveysStateModel>) {
    ctx.dispatch(new Navigate(['survey', 'success']));
  }

  @Action(SurveysAction.GetAll)
  public getAllSurveys(
    ctx: StateContext<SurveysStateModel>,
    { queries }: SurveysAction.GetAll
  ) {
    ctx.patchState({ loading: true, filters: queries });
    return this.surveysService.getAllSurveys(queries).pipe(
      tap((payload) => {
        ctx.patchState({
          surveys: this.transformData(payload),
          loading: false,
        });
      }),
      catchError((error) => ctx.dispatch(new SurveysAction.Failure(error)))
    );
  }

  @Action(SurveysAction.GetByID)
  public getSurveyByID(
    ctx: StateContext<SurveysStateModel>,
    { queries, id }: SurveysAction.GetByID
  ) {
    ctx.patchState({ loading: true });
    return this.surveysService.getSurveysByID(id, queries).pipe(
      tap((payload) => {
        ctx.patchState({
          survey: payload,
          loading: false,
        });
      }),
      catchError((error) => ctx.dispatch(new SurveysAction.Failure(error)))
    );
  }

  @Action(SurveysAction.ValidationCaptcha)
  public validationCaptcha(
    ctx: StateContext<SurveysStateModel>,
    { body, token }: SurveysAction.ValidationCaptcha
  ) {
    ctx.patchState({ loadingResponse: true });
    return this.surveysService.recaptchaValidation({ token }).pipe(
      mergeMap(() => ctx.dispatch(new SurveysAction.Response(body))),
      catchError((error) => ctx.dispatch(new SurveysAction.Failure(error)))
    );
  }

  @Action(SurveysAction.Response)
  public responseSurvey(
    ctx: StateContext<SurveysStateModel>,
    { body }: SurveysAction.Response
  ) {
    return this.surveysService.surveyResponse(body).pipe(
      tap(() => {
        ctx.patchState({
          loadingResponse: false,
        });
      }),
      mergeMap(()=>ctx.dispatch(new SurveysAction.ResponseSuccess())),
      catchError((error) => ctx.dispatch(new SurveysAction.Failure(error)))
    );
  }

  @Action(SurveysAction.Create)
  public createSurveys(
    ctx: StateContext<SurveysStateModel>,
    { body }: SurveysAction.Create
  ) {
    ctx.patchState({ loading: true });
    return this.surveysService.createSurveys(body).pipe(
      tap(() => {
        ctx.patchState({
          loading: false,
        });
      }),
      catchError((error) => ctx.dispatch(new SurveysAction.Failure(error)))
    );
  }

  @Action(SurveysAction.Update)
  public updateSurveys(
    ctx: StateContext<SurveysStateModel>,
    { body, surveysID }: SurveysAction.Update
  ) {
    ctx.patchState({ loading: true });
    return this.surveysService.updateSurveys(body, surveysID).pipe(
      tap(() => {
        ctx.patchState({
          loading: false,
        });
      }),
      catchError((error) => ctx.dispatch(new SurveysAction.Failure(error)))
    );
  }

  @Action(SurveysAction.Delete)
  public deleteSurveys(
    ctx: StateContext<SurveysStateModel>,
    { surveysID }: SurveysAction.Delete
  ) {
    const { filters } = ctx.getState();
    return this.surveysService.deleteSurveys(surveysID).pipe(
      mergeMap(() => ctx.dispatch(new SurveysAction.GetAll(filters))),
      catchError((error) => ctx.dispatch(new SurveysAction.Failure(error)))
    );
  }

  public transformData(data: SurveysModels.List): SurveysModels.List {
    return {
      ...data,
      results: data.results.map((item) => ({
        ...item,
        question_count: item.questions.length,
      })),
    };
  }
}
