import { Injectable, inject } from '@angular/core';
import { BASE_URL_API, HttpService } from '@carwash-project/modules/core';
import {
  RecaptchaResponse,
  SurveyResponseModel,
  SurveyResultsModel,
  SurveysCreateModel,
  SurveysModels,
  SurveysUpdateModel,
} from '../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SurveysService {
  private readonly baseApi: string = inject(BASE_URL_API);
  private readonly httpService = inject(HttpService);
  private readonly mainPath = `${this.baseApi}surveys`;

  public getAllSurveys(
    queries: SurveysModels.Queries
  ): Observable<SurveysModels.List> {
    return this.httpService.get(
      this.mainPath,
      queries,
      'Error al consultar las encuestas.'
    );
  }

  public getSurveysByID(
    surveysID: number | string,
    queries: SurveysModels.Queries | null
  ): Observable<SurveysModels.Surveys> {
    const url = `${this.mainPath}/${surveysID}`;
    return this.httpService.get(
      url,
      queries,
      'Error al consultar la encuesta.'
    );
  }

  public createSurveys(
    body: SurveysCreateModel.Request
  ): Observable<SurveysModels.Surveys> {
    return this.httpService.post(
      this.mainPath,
      body,
      'Error al crear la encuesta.'
    );
  }

  public updateSurveys(
    body: SurveysUpdateModel.Request,
    surveysID: number | string
  ): Observable<SurveysModels.Surveys> {
    const url = `${this.mainPath}/${surveysID}`;
    return this.httpService.patch(
      url,
      body,
      'Error al actualizar la encuesta.'
    );
  }

  public deleteSurveys(
    surveysID: number | string
  ): Observable<SurveysModels.Surveys> {
    const url = `${this.mainPath}/${surveysID}`;
    return this.httpService.delete(url, null, 'Error al eliminar la encuesta.');
  }

  public surveyResponse(
    body: SurveyResponseModel.Request
  ): Observable<SurveyResponseModel.Response> {
    const url = `${this.baseApi}surveyresponses/`;
    return this.httpService.post(url, body, 'Error al enviar la encuesta.');
  }

  public recaptchaValidation(body: {
    token: string;
  }): Observable<RecaptchaResponse> {
    const url = `${this.baseApi}recaptcha/`;
    return this.httpService.post(url, body, 'Error al validar captcha.');
  }

  public surveyResults(
    queries: SurveyResultsModel.Request | null
  ): Observable<SurveyResultsModel.Response> {
    const url = `${this.baseApi}reporting/survey-responses/`;
    return this.httpService.get(url, queries, 'Error al cargar el reporte.');
  }

  public surveyReportXlsx(
    queries: SurveyResultsModel.Request | null
  ): Observable<SurveyResultsModel.ReportXlsx> {
    const url = `${this.baseApi}reporting/survey-responses/`;
    return this.httpService.get(url, queries, 'Error al descargar el reporte.');
  }
}
